import React, { cloneElement, Children } from 'react'
import PropTypes from 'prop-types'

import { Row } from 'components/layout'

import Close from 'assets/svg/icons/modal-close.svg'

import s from './Contact.scss'

const Contact = ({ heading, hasClose, onClose, children }) => (
  <div className={s.contact}>
    {hasClose && onClose && (
      <span className={s.contact__close} onClick={() => onClose && onClose()}>
        <Close />
      </span>
    )}
    {heading && <h1 className={s.contact__heading}>{heading}</h1>}
    <Row>
      {Children.map(
        children,
        (child, i) =>
          child && (
            // eslint-disable-next-line react/no-array-index-key
            <div className={s.contact__item} key={`${s.contact}-${i}`}>
              {cloneElement(child, {
                className: s.contact__input,
              })}
            </div>
          )
      )}
    </Row>
  </div>
)

Contact.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  hasClose: PropTypes.bool,
  onClose: PropTypes.func,
}

Contact.defaultProps = {
  children: undefined,
  hasClose: false,
}

export default Contact
