import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';

import s from './AccessSystemPopup.scss';

export default class AccessSystemPopup extends Component {

  static propTypes = {
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    value: PropTypes.string,
  }

  static defaultProps = {
    onCancel: () => {},
    onChange: () => {},
    onClose: () => {},
    value: '',
  }

  handleTextareaChange = (event) => {
    this.props.onChange(event.target.value);
  }

  render() {
    const { onCancel, onClose, value } = this.props;

    return (
      <Fragment>
        <textarea
          value={value}
          onChange={this.handleTextareaChange}
          className={s.popup__textarea}
          placeholder="Aveleon Systems"
          rows="5"
        />
        <div className={s.popup__button}>
          <Button onClick={onClose}>
            Save Selection
          </Button>
        </div>
        <Button onClick={onCancel} color="transparent">
          Cancel
        </Button>
      </Fragment>
    );
  }
}
