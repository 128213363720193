import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Row } from 'components/layout';
import Image from 'components/image';

import s from './CheckboxGrid.scss';

const NOT_SURE = 'Not sure';

export default class CheckboxGrid extends PureComponent {

  selected = [];

  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    onClickOther: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.exact({
      logo: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    otherSystems: PropTypes.bool,
    value: PropTypes.instanceOf(Set),
  }

  static defaultProps = {
    name: 'accessSystems',
    onChange: () => {},
    onClickOther: () => {},
    options: [],
    otherSystems: false,
    value: [],
  }

  handleCheckboxChange = (event, value) => {
    const { checked } = event.target;
    const values = new Set(this.props.value);

    if (checked) {
      // clear other entries if you are not sure
      if (value === NOT_SURE) {
        values.clear();
      }

      // remove "not sure" if you are now sure but werent before
      if (values.values().next().value === NOT_SURE) {
        values.clear();
      }
      //
      values.add(value);
    } else {
      // just delete whatever you've unchecked
      values.delete(value);
    }

    this.props.onChange(values);
  };

  renderCheckbox(option) {
    const { name, value } = this.props;

    return (
      <div className={s.checkboxGrid__col} key={option.value}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label className={s.checkboxGrid__label} title={option.value}>
          <input
            className={s.checkboxGrid__checkbox}
            checked={value.has(option.value)}
            onChange={event => this.handleCheckboxChange(event, option.value)}
            type="checkbox"
            name={name}
            value={option.value}
          />
          <div className={s.checkboxGrid__item}>
            {option.logo && (
              <div className={s.checkboxGrid__logo}>
                <Image src={option.logo} transition="none" />
              </div>
            )}
          </div>
        </label>
      </div>
    );
  }

  render() {
    const { name, options, otherSystems, onClickOther, value } = this.props;

    return (
      <div className={s(s.checkboxGrid, { otherSystems })}>
        <Row>
          {options.map(option => this.renderCheckbox(option))}
          <div className={s.checkboxGrid__col}>
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <button className={s.checkboxGrid__label} type="button" onClick={onClickOther}>
              <div className={s('checkboxGrid__item', 'checkboxGrid__itemOther')}>
                <div className={s.checkboxGrid__itemText}>Other</div>
              </div>
            </button>
          </div>
          <div className={s.checkboxGrid__col}>
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label className={s.checkboxGrid__label} title="Not sure" htmlFor="checkboxgrid_not_sure">
              <input
                id="checkboxgrid_not_sure"
                className={s.checkboxGrid__checkbox}
                checked={value.has(NOT_SURE)}
                onChange={event => this.handleCheckboxChange(event, NOT_SURE)}
                type="checkbox"
                name={name}
                value={NOT_SURE}
              />
              <div className={s.checkboxGrid__item}>
                <div className={s.checkboxGrid__itemText}>I’m not sure</div>
              </div>
            </label>
          </div>
        </Row>
      </div>
    );
  }
}
