import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/layout';

import { ModalHeader } from '.';

import s from './Modal.scss';

const Modal = ({ children, closeRoute }) => (
  <div className={s.modal}>
    <ModalHeader closeRoute={closeRoute} />
    <div className={s.modal__inner}>
      <Container>
        {children}
      </Container>
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.node,
  closeRoute: PropTypes.string,
};

Modal.defaultProps = {
  children: undefined,
};

export default Modal;
