import React from 'react';
import PropTypes from 'prop-types';

import s from './Price.scss';

const Price = ({ currency, amount, suffix, centered, className }) => (
  <div className={s('price', className, { centered })}>
    <span className={s.price__currency}>{currency}</span>
    <strong className={s.price__amount}>{amount.toLocaleString('en-US', { minimumIntegerDigits: 2, currency: 'USD' })}</strong>
    {suffix && <span className={s.price__suffix}>{suffix}</span>}
  </div>
);

Price.propTypes = {
  currency: PropTypes.string,
  amount: PropTypes.number,
  suffix: PropTypes.string,
  centered: PropTypes.bool,
  className: PropTypes.string,
};

Price.defaultProps = {
  currency: '$',
};

export default Price;
