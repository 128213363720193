import { graphql } from 'gatsby';
import PricingCalculate from 'routes/pricing-calculate/PricingCalculate';

export default PricingCalculate;

export const query = graphql`
  query PricingCalculate {
    site {
      siteMetadata {
        functionsUrl
      }
    }
    page: contentfulPagePricing {
      metaTitle
      metaDescription
      metaImage {
        ...image
      }
      existingAccessSystems: pricingCalculatorExistingAccessSystems {
        id
        name
        logo {
          file {
            url
          }
        }
      }
      maxDoors: pricingCalculatorMaxDoors,
      contactPrompt: pricingCalculatorContactPrompt {
        contactPrompt: pricingCalculatorContactPrompt
      }
      successMessage: pricingCalculatorSuccessMessage
      errorMessage: pricingCalculatorErrorMessage
    }
  }
`;
