import React from 'react';
import PropTypes from 'prop-types';

import s from './TextInput.scss';

const TextInput = ({ type, value, block, style, className, placeholder, ...props }) => (
  <div className={s('textInput', className, { block })}>
    <input style={style} className={s('textInput__input', type)} type={type} value={value} placeholder={placeholder} {...props} />
  </div>
);

TextInput.propTypes = {
  type: PropTypes.oneOf(['number', 'email', 'text', 'tel']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  block: PropTypes.bool,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

TextInput.defaultProps = {
  type: 'text',
};

export default TextInput;
