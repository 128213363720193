import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';
import { Container } from 'components/layout';

import Close from 'assets/svg/icons/modal-close.svg';

import s from './Popup.scss';

export default class Popup extends Component {

  state = {
    isOpen: this.props.open,
  }

  static propTypes = {
    children: PropTypes.node,
    noPadding: PropTypes.bool,
    heading: PropTypes.string,
    onClose: PropTypes.func,
    successElement: PropTypes.element,
    hasSubmit: PropTypes.bool,
  }

  static defaultProps = {
    children: undefined,
    noPadding: false,
    onClose: () => null,
    successElement: null,
    hasSubmit: false,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ isOpen: this.props.open });
    }

    if (this.props.hasSubmit) {
      setTimeout(() => {
        this.setState({
          isOpen: false,
        });
      }, 900);
    }
  }

  componentWillUnmount() {
    this.setState({ isOpen: false });
  }

  onClose = () => {
    this.setState({
      isOpen: false,
    }, this.props.onClose());
  }

  render() {
    const { heading, children, noPadding, successElement, hasSubmit } = this.props;
    const { isOpen } = this.state;

    // console.log('Has submit in Popup is ', hasSubmit);

    return (
      <div className={s('popup', { isOpen })}>
        <button className={s('popup__backdrop')} onClick={this.onClose} />
        <div className={s('popup__inner', { noPadding, hasSubmit })}>
          {
            !hasSubmit &&
            <Clickable className={s.popup__closeButton} onClick={this.onClose}>
              <Close />
            </Clickable>
          }
          <Container className={s({ noPadding })}>
            { successElement && <div className={s('successElement', { hasSubmit })}>{successElement}</div> }
            { <div className={s('content', { hasSubmit })}>{heading && (<h1 className={s.popup__heading}>{heading}</h1>)}</div>}
            { <div className={s('content', { hasSubmit })}>{children}</div> }
          </Container>
        </div>
      </div>
    );
  }
}
