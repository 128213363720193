import React from 'react';
import PropTypes from 'prop-types';
import s from './FormError.scss';

const FormError = ({ children }) => (
  <p className={s.formError}>
    {children}
  </p>
);

FormError.propTypes = {
  children: PropTypes.string,
};

FormError.defaultProps = {
  children: '',
};

export default FormError;
