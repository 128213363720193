import React from 'react';
import PropTypes from 'prop-types';

import Envelope from 'assets/svg/icons/envelope.svg';

import s from './EmailButton.scss';

const EmailButton = ({ children, onClick }) => (
  <button className={s.emailButton} onClick={onClick}>
    <Envelope /> {children}
  </button>
);

EmailButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

EmailButton.defaultProps = {
  children: 'Email me',
};

export default EmailButton;
