import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';

import ArrowSmall from 'assets/svg/icons/arrow-small.svg';

import s from './Step.scss';

const Step = ({ currentStep, totalSteps, onPrev, onNext, disabled, heading, children }) => (
  <div className={s.step}>
    {currentStep && (
      <small className={s.step__status}>
        Step {currentStep} of {totalSteps}
      </small>
    )}

    <fieldset className={s.step__fieldset}>
      {heading && (<legend className={s.step__legend}>{heading}</legend>)}
      <div className={s.step__content}>
        {children}
      </div>
    </fieldset>

    <div className={s.step__nav}>
      {onPrev && (
        <Button className={s.step__button} color="transparent" onClick={onPrev}>
          <ArrowSmall className={s.step__arrow} />
          Back
        </Button>
      )}
      {onNext && (
        <Button className={s.step__button} disabled={disabled} onClick={onNext}>
          Next
          <ArrowSmall className={s('step__arrow', 'step__arrowNext')} />
        </Button>
      )}
    </div>
  </div>
);

Step.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  disabled: PropTypes.bool,
};

Step.defaultProps = {
  children: undefined,
  totalSteps: 3,
};

export default Step;
