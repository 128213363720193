import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Price from 'components/price'

import s from './Table.scss'

const Table = ({ tableHead, tableBody, total, emailButton }) => (
  <Fragment>
    <table className={s.table}>
      <thead>
        {tableHead.map(row => (
          <tr key={row.join()}>
            {row.map(col => (
              <th key={col}>{col}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {tableBody.map(row => (
          <tr key={row.join()}>
            {row.map(col => (
              <td key={col}>{col}</td>
            ))}
          </tr>
        ))}
      </tbody>

      <tfoot>
        <tr>
          <td colSpan="3" className={s.table__total}>
            <Price className={s.table__price} amount={total} />
          </td>
        </tr>
      </tfoot>
    </table>

    {emailButton && <div className={s.table__button}>{emailButton}</div>}
  </Fragment>
)

Table.propTypes = {
  tableHead: PropTypes.array,
  tableBody: PropTypes.array,
  total: PropTypes.number,
  emailButton: PropTypes.element,
}

export default Table
