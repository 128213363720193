/* eslint-disable jsx-a11y/no-autoFocus, jsx-a11y/label-has-for */

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import createPricingTable from 'shared/create-pricing-table'
import Markdown from 'components/markdown'

import Button from 'components/button'
import Select from 'components/select'
import EmailButton from 'components/email-button'
import Popup from 'components/popup'
import TextInput from 'components/text-input'

import { Step, Table, Contact, FormError } from '.'

// Price of products for new users
const NEW_SYSTEM_COSTS = {
  MOBILE_READER_EDGE: 299,
  MOBILE_ACCESS_PLAN: 348,
}

// Price of products for users with existing access systems
const EXISTING_SYSTEM_COSTS = {
  MOBILE_READER_PRO: 349,
  MOBILE_ACCESS_CONNECTOR: 1548,
  MOBILE_ACCESS_PLAN: 588,
}

const LINK_SHOP = 'https://shop.proxy.com/products/proxy-mobile-reader-edge'

const FormStatus = {
  Default: 'Default',
  Sending: 'Sending',
  Success: 'Success',
  Error: 'Error',
}

const PricingCalculator = props => {
  const [currentStep, setCurrentStep] = useState(1)
  const [doorCount, setDoorCount] = useState(1)
  const [contactForm, setContactForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
  })
  const [contactFormStatus, setContactFormStatus] = useState(FormStatus.Default)
  const ipAddress = useRef(null)
  const userId = useRef(null)

  const newSystem = props.kind === 'new'

  useEffect(() => {
    const getIPAddress = async () => {
      // Fetch the user's IP address so it can be logged in the spreadsheet
      const res = await fetch('https://api.ipify.org/?format=json')

      if (res.ok) {
        const { ip } = await res.json()
        ipAddress.current = ip
      }
    }

    getIPAddress()
  }, [])

  const prevStep = () => {
    setCurrentStep(state => Math.max(state - 1, 1))
  }

  const nextStep = () => {
    setCurrentStep(state => Math.min(state + 1, 3))
  }

  // JSON object to send to the server
  const getFormData = () => {
    return {
      id: userId.current,
      ipAddress: ipAddress.current,
      newSystem,
      doorCount,
      doorHardware: false,
      currentAccessSystems: '',
      ...(newSystem ? getNewSystemEstimate() : getExistingSystemEstimate()),
    }
  }

  const updateContactForm = key => {
    return event => {
      const { value } = event.target

      setContactForm(state => ({
        ...state,
        [key]: value,
      }))
    }
  }

  // Estimate to use if the user is starting from scratch
  const getNewSystemEstimate = () => {
    const mobileReaderEdgeCount = doorCount
    const mobileAccessPlanCount = doorCount

    const mobileReaderEdgeSubtotal =
      mobileReaderEdgeCount * NEW_SYSTEM_COSTS.MOBILE_READER_EDGE
    const mobileAccessPlanSubtotal =
      mobileAccessPlanCount * NEW_SYSTEM_COSTS.MOBILE_ACCESS_PLAN

    const totalCost = mobileReaderEdgeSubtotal + mobileAccessPlanSubtotal

    return {
      mobileReaderEdgeCount,
      mobileAccessPlanCount,
      mobileReaderEdgeSubtotal,
      mobileAccessPlanSubtotal,
      totalCost,
    }
  }

  // Estimate to use if the user is upgrading an existing access system
  const getExistingSystemEstimate = () => {
    const mobileReaderProCount = doorCount
    const mobileAccessPlanCount = doorCount

    const mobileReaderProSubtotal =
      mobileReaderProCount * EXISTING_SYSTEM_COSTS.MOBILE_READER_PRO
    const mobileAccessPlanSubtotal =
      mobileAccessPlanCount * EXISTING_SYSTEM_COSTS.MOBILE_ACCESS_PLAN

    const totalCost = mobileReaderProSubtotal + mobileAccessPlanSubtotal

    return {
      mobileReaderProCount,
      mobileAccessPlanCount,
      mobileReaderProSubtotal,
      mobileAccessPlanSubtotal,
      totalCost,
    }
  }

  /**
   * Ping an endpoint to store the set of questionnaire results, along with the date/time and user's
   * IP address, to a spreadsheet.
   * The endpoint returns a unique ID. This ID can be used to update the sheet if:
   *   - The user decides to email the quote to themselves, in which case their email address is
   *     stored.
   *   - The user fills out the HubSpot form, in which case the extra contact info is stored.
   */
  const logQuestionnaire = async () => {
    const res = await fetch(`${props.baseUrl}/save-quote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getFormData()),
    })

    if (res.ok) {
      const { id } = await res.json()
      userId.current = id
    }
  }

  /**
   * Ping an endpoint to submit the contact form to HubSpot.
   */
  const submitContactForm = async () => {
    return fetch(`${props.baseUrl}/submit-quote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...getFormData(),
        ...contactForm,
      }),
    })
  }

  /**
   * Ping an endpoint to email the user's quote to themselves.
   */
  const sendEmailQuote = async () => {
    return fetch(`${props.baseUrl}/email-quote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...getFormData(), email: contactForm.email }),
    })
  }

  switch (currentStep) {
    case 1: {
      const { maxDoors, contactPrompt } = props

      const inputStyle = {
        width: `${doorCount ? doorCount.toString().length : '1'}ch`,
      }

      return (
        <Step
          currentStep={currentStep}
          onNext={() => {
            logQuestionnaire()
            nextStep()
          }}
          disabled={
            !doorCount ||
            parseInt(doorCount, 10) <= 0 ||
            parseInt(doorCount, 10) > maxDoors
          }
          heading="How many doors are you looking to add mobile access to?"
        >
          <label>
            I want to add mobile access to
            <TextInput
              size={parseInt(doorCount, 10)}
              type="number"
              value={doorCount}
              onChange={e => {
                const number = parseInt(e.target.value, 10)

                setDoorCount(number)
              }}
              style={inputStyle}
              autoFocus
              placeholder="1"
              min="1"
              max="1000000"
            />
            door{parseInt(doorCount, 10) !== 1 && 's'}
          </label>
          {doorCount > maxDoors && <Markdown source={contactPrompt} />}
        </Step>
      )
    }
    case 2: {
      const { formSuccessMessage, formErrorMessage } = props
      // The form is only valid if every field is filled in
      const formIsValid = Object.keys(contactForm).reduce((isValid, key) => {
        if (contactForm[key].trim() === '') {
          return false
        }

        return isValid
      }, true)

      const formIsSending = contactFormStatus === FormStatus.Sending

      return (
        <Step
          currentStep={currentStep}
          onPrev={prevStep}
          onNext={async () => {
            try {
              setContactFormStatus(FormStatus.Sending)
              const [
                emailQuoteResponse,
                contactFormResponse,
              ] = await Promise.all([sendEmailQuote(), submitContactForm()])

              if (emailQuoteResponse.ok && contactFormResponse.ok) {
                setContactFormStatus(FormStatus.Success)
                nextStep()
              }
            } catch (error) {
              setContactFormStatus(FormStatus.Error)
            }
          }}
          disabled={!formIsValid || formIsSending}
        >
          <form>
            <Contact>
              <label>
                First name
                <TextInput
                  type="text"
                  value={contactForm.firstName}
                  onChange={updateContactForm('firstName')}
                  placeholder="First name"
                  required
                  block
                />
              </label>

              <label>
                Last name
                <TextInput
                  type="text"
                  value={contactForm.lastName}
                  onChange={updateContactForm('lastName')}
                  placeholder="Last name"
                  required
                  block
                />
              </label>

              <label>
                Work email
                <TextInput
                  type="email"
                  value={contactForm.email}
                  onChange={updateContactForm('email')}
                  required
                  placeholder="Email"
                  block
                />
              </label>

              <label>
                Company
                <TextInput
                  type="text"
                  value={contactForm.companyName}
                  onChange={updateContactForm('companyName')}
                  placeholder="Company"
                  required
                  block
                />
              </label>

              {contactFormStatus === FormStatus.Error && (
                <FormError>{formErrorMessage}</FormError>
              )}
            </Contact>
          </form>
        </Step>
      )
    }
    case 3: {
      const pricingTable = createPricingTable(getFormData())

      return (
        <Step
          currentStep={currentStep}
          heading="Your Quote Estimate"
          onPrev={prevStep}
        >
          <Table
            tableHead={pricingTable.head}
            tableBody={pricingTable.body}
            total={pricingTable.total}
          />
        </Step>
      )
    }
    default:
      return null
  }
}

export default PricingCalculator
