import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import extractMeta from 'utils/extract-meta';
import SEO from 'components/seo';
import Modal from 'components/modal';
import PricingCalculator from './components/pricing-calculator';

export default class PricingCalculate extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  render() {
    const { data, location } = this.props;
    const { kind = 'new' } = queryString.parse(location.search);

    return (
      <Modal closeRoute="/pricing">
        <SEO {...extractMeta(data.page)} />
        <PricingCalculator
          kind={kind}
          baseUrl={data.site.siteMetadata.functionsUrl}
          accessSystems={data.page.existingAccessSystems}
          maxDoors={data.page.maxDoors}
          contactPrompt={data.page.contactPrompt.contactPrompt}
          formSuccessMessage={data.page.successMessage}
          formErrorMessage={data.page.errorMessage}
        />
      </Modal>
    );
  }
}
