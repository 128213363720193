import React from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';

import ProxyLogo from 'assets/svg/proxy-logo-text.svg';
import Close from 'assets/svg/icons/modal-close.svg';

import s from './ModalHeader.scss';

const ModalHeader = ({ children, closeRoute }) => (
  <div className={s.modalHeader}>
    <div className={s.modalHeader__container}>
      <Clickable className={s.modalHeader__logo} to="/"><ProxyLogo /></Clickable>
      <Clickable className={s.modalHeader__close} to={closeRoute}><Close /></Clickable>
      {children}
    </div>
  </div>
);

ModalHeader.propTypes = {
  children: PropTypes.node,
  closeRoute: PropTypes.string,
};

ModalHeader.defaultProps = {
  children: undefined,
  closeRoute: '/',
};

export default ModalHeader;

