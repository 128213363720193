import React from 'react';
import PropTypes from 'prop-types';

import s from './Select.scss';

const Select = ({
  block,
  style,
  className,
  label,
  validate,
  validationLabel,
  children,
  topMargin,
  ...props
}) => (
  <div className={s.selectWrapper}>
    <div className={s('select', className, {
      block,
      validate,
      noLabel: !label,
      readOnly: props.readOnly,
    })}
    >
      <select id="select" style={style} className={s('field')} {...props}>
        { children }
      </select>
      { validationLabel &&
        <span className={s.error}>{validationLabel}</span>
      }
    </div>
  </div>
);

Select.propTypes = {
  block: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  validate: PropTypes.bool,
  validationLabel: PropTypes.string,
  topMargin: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Select.defaultProps = {
  validate: false,
  topMargin: true,
};

export default Select;
