import numeral from 'numeral';

const inflect = (word, quantity) => `${word}${quantity === 1 ? '' : 's'}`;

/**
 * Create the layout for a pricing table. This function is used both on the client and the server.
 * @param {Object} quote - Object containing pricing estimate. See the `PricingCalculator`
 * component for more details.
 * @returns {PricingTable} Object with rows and columns for a table head and body.
 */
export default function createPricingTable(quote) {
  const head = [['Item', 'Quantity', 'Subtotal']];

  if (quote.newSystem === null) {
    /**
     * Table structure.
     * @typedef {Object} PricingTable
     * @prop {Array.<Array.<String>>} head - Rows and cells for the table head.
     * @prop {Array.<Array.<String>>} body - Rows and cells for the table body.
     */
    return {
      head,
      body: [],
    };
  }

  if (quote.newSystem) {
    return {
      head,
      body: [
        [
          'Proxy Mobile Reader Edge',
          `${quote.mobileReaderEdgeCount} ${inflect('door', quote.mobileReaderEdgeCount)}`,
          `$${numeral(quote.mobileReaderEdgeSubtotal).format('0,0')}`,
        ],
        [
          'Proxy Mobile Access Plan',
          `${quote.mobileAccessPlanCount} ${inflect('door', quote.mobileAccessPlanCount)} / 12 months`,
          `$${numeral(quote.mobileAccessPlanSubtotal).format('0,0')}`,
        ],
        [
          'User Credentials',
          'Unlimited',
          '$0',
        ],
      ],
      total: quote.totalCost,
    };
  }

  return {
    head,
    body: [
      [
        'Proxy Mobile Reader Pro',
        `${quote.mobileReaderProCount} ${inflect('door', quote.mobileReaderProCount)}`,
        `$${numeral(quote.mobileReaderProSubtotal).format('0,0')}`,
      ],
      [
        'Proxy Mobile Access Plan',
        `${quote.mobileAccessPlanCount} ${inflect('door', quote.mobileAccessPlanCount)} / 12 months`,
        `$${numeral(quote.mobileAccessPlanSubtotal).format('0,0')}`,
      ],
      [
        'User Credentials',
        'Unlimited',
        '$0',
      ],
    ],
    total: quote.totalCost,
  };
}
